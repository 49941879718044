import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/about-us/",
    label: "About Us",
  },
]

const bioData = [
  {
    id: 1,
    full: "Chris Nyhuis",
    title: "Co-Founder and CEO",
    bio: "Chris Nyhuis is the Founder and CEO of Vigilant, a cyber security services and technology company that specializes in identifying, isolating, and mitigating threats with unprecedented precision. A sought-after speaker and instructor on Cyberwarfare, Chris’s extensive experience has prepared him to understand today’s cybersecurity landscape and the intricacies of cyber threats facing organizations. Chris holds multiple patents which ensure data integrity for trusted evolving detection systems.\n\nPrior to founding Vigilant in 2009, Chris served as the CISO of a national automotive data analytics company where he developed groundbreaking methods for defending the organization’s large datasets against advanced cyber-attacks. Chris has also spent time in the food supply chain and telecommunications industries defending and fortifying SCADA infrastructure environments.\n\nChris is a passionate philanthropist and social-entrepreneur who established Vigilant’s business model to provide social good around in the world. Under Chris’ leadership, 25% of Vigilant’s profit are directed toward orphan, foster care and anti-child-slavery organizations.",
    sauce: "../images/headshots/chris.jpg",
  },
  {
    id: 2,
    full: "KATIE NYHUIS",
    title: "CO-FOUNDER",
    bio: "Katie was originally responsible for spearheading accounting when co-founding Vigilant and while her focus remains within the finance department, she also puts her naturally detail-oriented skill sets to work in support of each department as needed. She has enjoyed watching Vigilant fill with amazing people with purpose and enjoys being a part of something greater than herself. Outside of work, she’s mom to three amazing kids who share her passion for sports and teach her more than books ever could.",
    sauce: "../images/headshots/katie.jpg",
  },
  {
    id: 3,
    full: "MIGUEL LOPEZ",
    title: "CHIEF FINANCIAL OFFICER ",
    bio: "Miguel is responsible for crafting winning business strategies and delivering superior financial results. As a recognized change agent, Miguel’s broad experience in Finance Administration was achieved in leadership positions at Procter and Gamble. Miguel appreciates how much Vigilant gives to those in need and loves seeing company values reflected in their finances. Miguel came to the US from Mexico over 20 years ago and stays deeply connected to his extended family there. Outside of Vigilant, he and his wife Kate are the proud parents of their “soccer superstar son,” Alex.",
    sauce: "../images/headshots/miguel.jpg",
  },
  {
    id: 4,
    full: "DAN BEAVIN",
    title: "CHIEF OPERATING OFFICER",
    bio: "Dan leads the security services teams for Vigilant. Dan has worked with companies to develop their defensive countermeasures, provide offensive security services, and built internal teams to support these initiatives. Dan spent 7 years in an offensive security role playing an ethical hacker for companies of many industries all around the world. Understanding the mind of an attacker has helped position Dan to understand how we can defend against them. Vigilant’s mission around orphan and foster care and fighting human trafficking are the driving forces for him. When not at work, Dan spends time with his wife and two boys playing hot wheels and LEGO.",
    sauce: "../images/headshots/dan-beavins.jpg",
  },
  {
    id: 7,
    full: "ARIEL ACUNA",
    title: "CHIEF PEOPLE OFFICER",
    bio: "Ariel leads the HR strategy and development efforts at Vigilant. He developed and implemented a strategic people plan that aligns with the mission and drives the overall culture. He continually works to help each employee at Vigilant succeed in their career path. He experienced a “coming home” feeling when he joined the company and is passionate about helping others feel the same.  When not leveraging his skills in talent management, coaching and employee engagement, he is a busy father of four – often touring the country with his family in their RV – seeking adventure.",
    sauce: "../images/headshots/ariel.jpg",
  },
  {
    id: 8,
    full: "JACOB KOFRON",
    title: "VP, ENGINEERING ",
    bio: "Jacob began his web application consulting career while still in high school – helping medical research, design and telecom clients before joining Vigilant more than 7 years ago – where he now plans, builds and manages the development of customer-facing and internal apps from the ground floor. When not busy planning his upcoming wedding with his fiancé, Jacob enjoys collecting rare science fiction and tinkering with vintage electronics.",
    sauce: "../images/headshots/jacob.jpg",
  },
]

const AboutUs = () => (
  <Layout>
    <Seo title="About Us" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <section className="section-analysis grid--bg --about-us-hero">
      <div className="container">
        <div className="row">
          <div className="section col-sm-4">
            <hr />
            <h1>
              ABOUT
              <br />
              VIGILANT
            </h1>

            <p>
              At Vigilant, our integrated team magnifies our collective skills{" "}
              and proprietary technologies to defend our clients, while
              delivering on an industry-best security protection record.
            </p>
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>
    <section className="section-copy-img-r about-us--hero-img-1">
      <div className="container">
        <div className="row">
          <div className="section-col-1 desk-only">
            <div className="side-binder about-us--gives">
              <h1>
                OUR <br />
                MISSION
              </h1>
              <h3>TO BE VIGILANT</h3>
            </div>
          </div>
          <div className="section-col-2">
            <div className="side-binder about-us--gives mob-only">
              <h1>OUR MISSION</h1>
              <h3>TO BE VIGILANT</h3>
            </div>
            <p>
              We defend and protect with a vengeance – people, businesses and
              society – and against those who exploit weakness: We innovate to
              solve challenges. We deliver the best possible solution. We use
              business as a force for good. We build a secure and stable future
              for all.
            </p>
            <p>
              Our mission is active in three critical areas: 1) defending the{" "}
              <b>Vigilant Family</b> so we all will have a bright and thriving
              future, 2) protecting <b>Our Clients’</b> people, production and
              profits, 3) fighting for <b>Vulnerable Children</b> around the
              world, so they may enjoy their freedom.
            </p>
            <p>
              Our Core Values are: Faith and Family First. Never Quit. Fight to
              Defend Others. Give Generously. Take Responsibility. Act with
              Integrity and Respect.
            </p>
            <hr />
            <p className="p-quote qqoute">
              “Our organization is great! They treat us with respect and
              integrity. They take our opinions and considerations seriously and
              are always asking for ways to improve. They are family first and
              if anything is needed by your home life, they are there to support
              you.”{" "}
            </p>
            <p className="p-quote--author qqoute--author ">
              - VIGILANT TEAM MEMBER
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="section-copy-img-l about-us--hero-img-2">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="side-binder about-us--gives mob-only">
              <h1>
                VIGILANT <br />
                GIVES BACK
              </h1>
              <h3>DEFENDING AT HOME AND ABROAD</h3>
            </div>
            <p>
              Vigilant exists “to defend and protect our clients with a
              vengeance.” But it doesn’t end there. Our heart is so committed to
              this part of our mission that we give 25% or our net income to
              global efforts to support orphans and to rescue trafficked
              children.
            </p>
            <p>
              There are 153 million orphans around the world and there are an
              estimated 10 million trafficked children today working in
              sweatshops, mines, as street workers and conflict soldiers.{" "}
            </p>
            <p>
              By directing 25% of Vigilant’s profit toward international orphan,
              foster care and anti-child-slavery organizations, Vigilant is able
              to protect and serve both its customers and highly vulnerable
              populations around the world.
            </p>
            <p>
              We call it the “Vigilante Mindset.” And when working with
              Vigilant, you join us in defending and protecting those both at
              home and around the world.
            </p>
            <hr />
            <p className="p-quote">
              “What I see in Vigilant is a meaningful purpose: what the company
              does, who the company is and their mission to support the causes
              they believe in… it says so much about a community of human beings
              that are all there for the same reason… and somebody who had the
              wherewithal to create that… I wish it was more common.”{" "}
            </p>

            <p className="p-quote--author">- VIGILANT TEAM MEMBER</p>
          </div>
          <div className="section-col-2 desk-only">
            <div className="side-binder about-us--gives">
              <h1>
                VIGILANT <br />
                GIVES BACK
              </h1>
              <h3>
                DEFENDING AT HOME
                <br />
                AND ABROAD
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-blocks-6">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="side-binder">
              <h6>VIGILANT</h6>
              <h3>VALUES</h3>
            </div>
            <h5 className="about-us">WORDS TO WORK BY</h5>
            <p>
              True values are not just words stored in a 3-ring binder filed in
              a cabinet <br />
              somewhere. Vigilant truly makes these words a part of the DNA
              <br /> in our work, relationships, service and culture. 
            </p>
          </div>
          <div className="section-col-2">
            <ul className="section-values--cards">
              <li>
                <ul className="section-values--inner-card">
                  <li className="block-img">
                    <StaticImage
                      src="../images/about-us/vigilant_icons-51.svg"
                      quality={95}
                      formats={["png"]}
                      alt="Logo Holder"
                      layout="fullWidth"
                      className="img-fluid"
                    />
                  </li>
                  <li className="block-title">NEVER QUIT</li>
                  <li className="block-content">
                    Our goal is to win, period. We don’t quit on each other.
                    Quitting isn’t in our vocabulary; we will do what it takes.
                  </li>
                </ul>
              </li>
              <li>
                <ul className="section-values--inner-card">
                  <li className="block-img">
                    <StaticImage
                      src="../images/about-us/vigilant_icons-48.svg"
                      quality={95}
                      formats={["png"]}
                      alt="Logo Holder"
                      layout="fullWidth"
                      className="img-fluid"
                    />
                  </li>
                  <li className="block-title">
                    FIGHT TO <br />
                    DEFEND OTHERS
                  </li>
                  <li className="block-content">
                    We fight with a vengeance to keep companies in business,
                    defend our families and take care of our teammates.
                  </li>
                </ul>
              </li>
              <li>
                <ul className="section-values--inner-card">
                  <li className="block-img">
                    <StaticImage
                      src="../images/about-us/vigilant_icons-49.svg"
                      quality={95}
                      formats={["png"]}
                      alt="Logo Holder"
                      layout="fullWidth"
                      className="img-fluid"
                    />
                  </li>
                  <li className="block-title">
                    FAITH AND
                    <br /> FAMILY FIRST
                  </li>
                  <li className="block-content">
                    Our faith and our families always come first. This is the
                    foundation of who we are and keeps us rooted in our mission.
                  </li>
                </ul>
              </li>
              <li>
                <ul className="section-values--inner-card">
                  <li className="block-img">
                    <StaticImage
                      src="../images/about-us/vigilant_icons-46.svg"
                      quality={95}
                      formats={["png"]}
                      alt="Logo Holder"
                      layout="fullWidth"
                      className="img-fluid"
                    />
                  </li>
                  <li className="block-title">GIVE GENEROUSLY</li>
                  <li className="block-content">
                    We live by the principle of security for all and donate our
                    time and 25% of our profits so we can break the cycle of
                    orphan care and human trafficking.
                  </li>
                </ul>
              </li>
              <li>
                <ul className="section-values--inner-card">
                  <li className="block-img">
                    <StaticImage
                      src="../images/about-us/vigilant_icons-47.svg"
                      quality={95}
                      formats={["png"]}
                      alt="Logo Holder"
                      layout="fullWidth"
                      className="img-fluid"
                    />
                  </li>
                  <li className="block-title">TAKE RESPONSIBILITY</li>
                  <li className="block-content">
                    In everything we do, we consider how our actions will impact
                    our clients and teammates. We take responsibility for those
                    actions.
                  </li>
                </ul>
              </li>
              <li>
                <ul className="section-values--inner-card">
                  <li className="block-img">
                    <StaticImage
                      src="../images/about-us/vigilant_icons-50.svg"
                      quality={95}
                      formats={["png"]}
                      alt="Logo Holder"
                      layout="fullWidth"
                      className="img-fluid"
                    />
                  </li>
                  <li className="block-title">
                    ACT WITH INTEGRITY <br />
                    AND RESPECT
                  </li>
                  <li className="block-content">
                    We operate according to what is right, not what is the
                    easiest. We make decisions with transparency and courage.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="section-box-cta">
      <div className="container">
        <div className="row">
          <div className="section-col-2">
            <div className="section-col-1--content">
              <p className="p-quote about-quote">
                “The flexibility and understanding that family comes first is an
                amazing thing. If there is an issue or you need to get something
                done in your personal life there is no hesitation in doing
                whatever they can to help.”
              </p>
              <hr />
              <p className="p-quote--author">- VIGILANT TEAM MEMBER</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div>

      <section className="section-bio">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>LEADERSHIP</h3>
              <h6>ACTING WITH INTEGRITY AND RESPECT</h6>
              <hr />
            </div>
            <div className="section-col-2">
              <div className="container">
                {bioData.map(bibio => (
                  <div className="row section-row" key={bibio.id}>
                    <div className="section-bio-img">
                      <img
                        width={211}
                        height={211}
                        src={bibio.sauce}
                        alt={bibio.full}
                      />
                      <h4>{bibio.full}</h4>
                    </div>
                    <div className="section-bio-content">
                      <h6>{bibio.title}</h6>
                      {bibio.bio.split("\n\n").map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default AboutUs
